<template>
  <v-container style="background-color: #4d4d4d" fluid fill-height pa-0>
      <img id="login-photo" :src="image" :height="screenHeight" :width="screenWidth" v-if="!mobile">
      <div class="login-box centered">
        <img :src="logo" alt="" id="logo" class="rounded">
        <v-container grid-list-xs>
          <v-layout column nowrap justify-space-around align-space-between>
            <v-flex xs8 pb-0>
              <v-select
                v-model="tenant"
                solo
                label="Select Company"
                :items="tenants"
                :loading="tenants.length === 0"
              />
            </v-flex>
            <v-flex xs4>
              <v-btn
                color="success"
                block
                :disabled="loggingIn || !tenant"
                @click="login"
              >
                <span v-if="!loggingIn">GO</span>
                <v-progress-circular
                  v-else
                  indeterminate
                  width="3"
                  size="28"
                  color="success"
                />
              </v-btn>
            </v-flex>
            <!-- Default Login -->
            <v-flex xs3 align-self-center>
              <v-slide-y-transition>
                <v-checkbox
                  v-if="tenants.length > 1"
                  v-model="defaultTenant"
                  label="Make default login"
                  color="success"
                  dark
                />
              </v-slide-y-transition>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    <span
      class="white--text"
      style="position: absolute; left: 10px; bottom: 0;"
    >{{ version }}</span>
  </v-container>
</template>

<script>
import { keycloak, URL_KEYCLOAK_REDIRECT_TENANT, URL_KEYCLOAK_REDIRECT_LOGOUT } from '@/api/keycloak'
import { LOCAL_STORAGE_KEYS, locStore } from '@/lib/LocalStorageWrapper'

export default {
  name: 'TenantSelection',
  computed: {
    isElectron () {
      return this.$store.getters['data/isElectron']
    },

    version () {
      return this.$store.getters['data/version']
    },

    mobile () {
      return this.userAgent.includes('iPhone') || this.userAgent.includes('Android')
    },

    tenants () {
      const tenants = this.$store.getters['auth/tenantStrings']
      const index = tenants?.findIndex(t => t === 'LOCAL')
      const doIt = this.onLocalHost && index === -1
      doIt && tenants.push('LOCAL')
      return tenants
    },

    onLocalHost () {
      return window.location.origin.includes('localhost')
    },

    tokensSet () {
      return this.$store.getters['auth/tokensAreSet']
    },

    userAgent () {
      return window.navigator.userAgent
    },

    authenticated () {
      return this.$store.state.auth.authenticated
    },

    loggingIn: {
      get () {
        return this.$store.state.auth.loggingIn
      },
      set (val) {
        this.$store.state.auth.loggingIn = val
      }
    }
  },
  watch: {
    tenants: function (value) {
      if (value.length > 0) {
        const tenant = locStore.get(LOCAL_STORAGE_KEYS.tenant)
        if (tenant) {
          this.tenant = tenant
        }
      }
      if (value.length === 1) {
        this.tenant = value[0]
        this.login()
      }
    },
    authenticated: function (value) {
      if (value) {
        const url = this.lastActiveUrl()
        if (url && url !== 'null') {
          this.$router.push({ name: url })
        } else {
          this.$router.push({ name: 'item' })
        }
      }
    },
    tenant: function (value) {
      if (value === 'LOCAL') {
        this.setInitialsForLocalEnv()
      }
      if (value) {
        locStore.set(LOCAL_STORAGE_KEYS.tenant, value.toLowerCase())
      }
      if (value && this.defaultTenant) {
        locStore.set(LOCAL_STORAGE_KEYS.defaultTenant, value.toLowerCase(), true)
      }
    },

    defaultTenant: function (value) {
      if (value && this.tenant) {
        locStore.set(LOCAL_STORAGE_KEYS.defaultTenant, this.tenant.toLowerCase(), true)
      } else if (!value) {
        locStore.remove(LOCAL_STORAGE_KEYS.defaultTenant)
      }
    },

    tokensSet: function (value) {
      if (value) {
        if (this.tenants.length === 1) {
          this.tenant = this.tenants[0]
          this.login()
        } else {
          const savedTenant = locStore.get(LOCAL_STORAGE_KEYS.defaultTenant)
          if (savedTenant) {
            this.tenant = savedTenant
            this.login()
          }
        }
      }
    }
  },
  data () {
    return {
      backgroundImages: [
        'abstract',
        'new-arch',
        'adventure',
        'architecture',
        'aerial',
        'citypanorama',
        'background-tech'
      ],
      image: '',
      logo: null,
      tenant: '',
      initials: '',
      defaultTenant: false,
      screenHeight: window.innerHeight,
      screenWidth: window.innerWidth
    }
  },
  methods: {
    getBackgroundImage () {
      this.image = require('@/assets/login-photos/background-tech.jpg')
    },

    lastActiveUrl () {
      return locStore.get(LOCAL_STORAGE_KEYS.lastActiveUrl)
    },

    setInitialsForLocalEnv () {
      locStore.set(LOCAL_STORAGE_KEYS.localInitials, 'DEV')
    },

    async login () {
      if (this.tenant) {
        this.$store.dispatch('auth/tenantLogin', { tenant: this.tenant.toLowerCase() })
          .catch(() => {
            this.loggingIn = false
            this.$store.dispatch('notifications/createSnackbar', {
              message: 'An error has occurred and has been logged.',
              color: 'error',
              top: true,
              canClose: true,
              timeout: 10000
            })
          })
        setTimeout(() => {
          if (!this.$store.state.auth.authenticated) {
            this.loggingIn = false
            this.$store.dispatch('notifications/createSnackbar', {
              message: 'An error has occurred. Please try again or contact support.',
              color: 'error',
              timeout: 5000,
              canClose: false,
              top: true
            })
          }
        }, 5000)
      }
    },

    setHeight () {
      this.screenHeight = document.documentElement.clientHeight
    },

    setWidth () {
      this.screenWidth = document.documentElement.clientWidth
    }
  },
  mounted () {
    this.getBackgroundImage()
    this.loggingIn = false
    this.logo = require('@/assets/logo.svg')

    this.$nextTick(function () {
      window.addEventListener('resize', this.setHeight)
      window.addEventListener('resize', this.setWidth)
    })

    setTimeout(() => {
      if (this.$store.state.auth.tenants.length === 0) {
        const refresh = locStore.get(LOCAL_STORAGE_KEYS.refreshToken)
        if (!window.location.href.includes('localhost')) {
          if (refresh) {
            try {
              keycloak.refreshToken = refresh
              keycloak.login({
                redirectUri: URL_KEYCLOAK_REDIRECT_TENANT
              })
            } catch (error) {
              keycloak.logout({ redirectUri: URL_KEYCLOAK_REDIRECT_LOGOUT })
            }
          } else {
            keycloak.logout({ redirectUri: URL_KEYCLOAK_REDIRECT_LOGOUT })
          }
        }
      }
    }, 3000)
  },
  beforeDestroy () {
    this.$store.commit('auth/changeLoggingInStatus', { bool: false })
    window.removeEventListener('resize', this.getHeight)
    window.removeEventListener('resize', this.setWidth)
  }
}
</script>
